export const ROUTES = {
  admin: {
    url: "/admin",
    createGym: {url: '/admin/create-gym'},
    loginProxy: {url: '/admin/login-proxy'},
    // manageContentPacks: {url: "/admin/manage-content-packs"},
    manageGym: {
      url: "/manage-gym",
      account: {
        manageAccount: {
          url: "/manage-gym/account"
        },
      },
      gymAccess: {url: "/manage-gym/gym-access"},
      appLayout: {
        url: "/manage-gym/app-layout",
        homeScreenButtons: {url: "/manage-gym/app-layout/home-buttons"},
        menuItems: {url: "/manage-gym/app-layout/menu-items"},
        otherFeatures: {url: "/manage-gym/app-layout/other-features"},
      },
      branding: {
        url: "/manage-gym/branding",
        theme: {
          url: "/manage-gym/branding/theme",
        },
        logo: {
          url: "/manage-gym/branding/logo"
        }
      },
      billing: {
        url: "/manage-gym/billing",
        account: {url: "/manage-gym/billing/account"},
        subscriptions: {
          url: "/manage-gym/billing/subscriptions",
          manage: {url: "/manage-gym/billing/subscriptions/manage/:productId"},
          create: {url: "/manage-gym/billing/subscriptions/create"},
        },
        blockBookings: {
          url: "/manage-gym/billing/blockBookings",
          manage: {url: "/manage-gym/billing/blockBookings/manage/:productId"},
          // create: {url: "/manage-gym/billing/blockBookings/create"},
        },
        packages: {
          url: "/manage-gym/billing/packages",
        },
        // memberships: {url: "/manage-gym/billing/memberships"},
        
      },
      gyms: {
        url: "/manage-gym/gyms",
      },
      // logSettings: {url: "/manage-gym/logSettings",}, // Moved to root level
      notifications: {
        url: "/manage-gym/notifications"
      },
      classes: {
        url: "/manage-gym/classes"
      },
      rooms: {
        url: "/manage-gym/rooms",
        add: "/manage-gym/rooms/add",
        edit: "/manage-gym/rooms/edit/:roomId",
      },
      selfRegistration: {url: "/manage-gym/self-registration"},
      sessionTypes: {
        url: "/manage-gym/sessionTypes",
        edit: {url: "/manage-gym/sessionTypes/edit/:id",},
        add: {url: "/manage-gym/sessionTypes/add/",},
      },
      trainers: {
        url: "/manage-gym/trainers",
        add: {url: "/manage-gym/trainers/add"}
      },
      reporting: {
        url: "/manage-gym/reporting",
        delinquency: {url: "/manage-gym/reporting/delinquency"},
        utilisation: {url: "/manage-gym/reporting/utilisation"},
        slippingAway: {url: "/manage-gym/reporting/slippingAway"},
      }
    },
  },
  clients: {
    add: {
      url: "/clients/add",
    },
    all: {
      url: `/clients/list`,
    },
    groups: {
      url: '/clients/groups',
    },
    logs: {
      url: "/clients/client-logs"
    },
    search: {
      url: '/clients/search',
    },
    view: {
      url: `/clients/view/:clientProfileId`,
      assessments: {
        add: { url: `/clients/view/:clientProfileId/assessments/add`},
        url: `/clients/view/:clientProfileId/assessments`,
      },
      measurements: {
        // add: { url: `/clients/view/:clientProfileId/measurements/add`},
        url: `/clients/view/:clientProfileId/measurements`,
      },
      photos: {
        // add: { url: `/clients/view/:clientProfileId/photos/add`},
        url: `/clients/view/:clientProfileId/photos`,
      },
      workouts: {url: `/clients/view/:clientProfileId/workouts`},
      nutrition: {url: `/clients/view/:clientProfileId/nutrition`},
      education: {
        url: `/clients/view/:clientProfileId/education`,
        // edit: {url: `/clients/view/:clientProfileId/education/edit/:lessonPlanId`},
        view: {url: `/clients/view/:clientProfileId/education/view/:lessonPlanId`},
      },
      logs: {url: `/clients/view/:clientProfileId/logs`},
      plan: {url: `/clients/view/:clientProfileId/plan`},
      bookings: {
        url: `/clients/view/:clientProfileId/bookings`,
        editSeries: {url: `/clients/view/:clientProfileId/bookings/edit/:id/:startTime/series`},
        editSession: {url: `/clients/view/:clientProfileId/bookings/edit/:id/:startTime/session`},
      },
      forms: {
        url: `/clients/view/:clientProfileId/forms`,
        edit: {url: `/clients/view/:clientProfileId/forms/edit-form-response/:responseId`},
        view: {url: `/clients/view/:clientProfileId/forms/view-form-response/:responseId`},
      },
      billing: {
        url: `/clients/view/:clientProfileId/billing`,
        payment: {url: `/clients/view/:clientProfileId/billing/payment/view/:paymentId`},
        subscription: {url: `/clients/view/:clientProfileId/billing/subscription/view/:subscriptionId`},
      },
      // notes: {url: `/clients/view/:clientProfileId/notes`},
    },
    
  },
  courses: {
    list: {url: "/courses"},
    add: {url: "/courses/add"},
    assign: {url: "/courses/edit/:courseId/assign"},
    edit: {url: "/courses/edit/:courseId"},
  },
  exercises: {
    list: {
      url: "/exercises",
    },
    edit: { url: `/exercises/edit/:exerciseId`}
  },
  education: {
      url: `/education`,
    lessons: {
      edit: { url: `/education/lessons/edit/:lessonId`},
      add: { url: `/education/lessons/add`},
      url: `/education/lessons`,
    },
    lessonPlans: {
      url: `/education/plans`,
      assign: {url: `/education/plans/assign/:lessonPlanId`},
      edit: { url: `/education/plans/edit/:lessonPlanId`},
      track: { url: `/education/plans/track/:lessonPlanId`},
      add: {url: `/education/plans/add`},
    },
    resources: {
      url: "/education/resources"
    }
  },
  forms: {
    url: "/forms",
    add: {url: "/forms/add"},
    edit: {url: "/forms/edit/:formId"},
    view: {url: "/forms/view/:formId"},
  },
  dailyLogs: {
    url: "/logs",
  },
  nutrition: {
    url: "/nutrition",
    mealPlans: {
      url: `/nutrition/mealplans`,
      add: {url: `/nutrition/mealplans/add`},
      edit: {url: `/nutrition/mealplans/edit/:mealPlanId`},
    },
    plans: {
      url: `/nutrition/plans`,
      add: {
        url: `/nutrition/plans/add`,
        day: {url: `/nutrition/plans/add/day`},
      },
      edit: {url: `/nutrition/plans/edit/:planId`},
    },
    days: {
      url: "/nutrition/days",
    }
  },
  recipes: {
    url: "/recipes",
    recipeBooks: {
      url: "/recipes/books",
      details: {url: "/recipes/books/:recipeBookId/details"},
      view: {url: "/recipes/books/view/:recipeBookId"},
      edit: {url: "/recipes/books/edit/:recipeBookId"},
      add: {url: "/recipes/books/add"},
    },
    list: {url: "/recipes/all"},
    add: {url: "/recipes/add"},
    assign: {url: "/recipes/edit/:recipeId/assign"},
    edit: {url: "/recipes/edit/:recipeId"},
    view: {url: "/recipes/view/:recipeId"},
  },
  registration: {
    join: {urls: ["/join/:gymCode/:registrationCode", "/join/:gymCode/:registrationCode/:trainerProfileId"]},
    joinWithBlockBooking: {
      start: {url: "/buy-block/:gymId/:priceId"},
      finish: {url: "/block-registration-finish/:gymId/:checkoutSessionId"},
    },
    joinWithPackage: {
      start: {url: "/package/:packageId"},
      finish: {url: "/package-finish/:gymId/:checkoutSessionId/:packageId"},
    },
    gymJoin: {urls: ["/gymJoin"]},
    recipeJoin: {urls: ["/recipeJoin"]},
    register: {url: "/register/:email/:userToken"},
    getStarted: {url: "/get-started"}
  },
  workoutTemplates: {
    url: `/workouts`,
    trackable: {url: "/workouts/templates"},
    video: {
      url: "/workouts/videoTemplates",
      add: {url: "/workouts/videoTemplates/add"},
      edit: {url: "/workouts/videoTemplates/edit/:workoutId"},
    }
  },
  bookings: {
    edit: {
      url: "/bookings/edit/:id/:startTime/series",
      series: {url: "/bookings/edit/:id/:startTime/series"},
      session: {url: "/bookings/edit/:id/:startTime/session"}
    }
  },
  settings: {
    url: "/settings",
  }
}


export const USER_ROUTES = {
  billing: {
    url: "/billing",
    paymentMethods: {url: "/billing/paymentMethods"},
    invoices: {url: "/billing/invoices"},
    blockBookings: {
      url: `/billing/block-bookings`,
      purchase: {url: `/billing/block-bookings/purchase`},
    }
    // invoices: {url: "/billing/invoices"}
  },
  classes: {
    url: "/classes",
    view: {url: "/classes/view/:id/:startTime"},
    purchase: {url: `/classes/purchase/:id/:startTime`}
  }
}
