'use client';
import React, {useEffect, useMemo} from "react";
let searchContext = null;

const getSearchContext = () => {
  if (!searchContext) {
    searchContext = React.createContext<ReturnType<typeof useSearchContextValue> | null>(null)
  }
  return searchContext;
}

export const SearchContextProvider = ({ children }) => {
  const value = useSearchContextValue();
  const SearchContext = useMemo(() => getSearchContext(), [])
  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
}

const useSearchContextValue = () => {
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [searchPage, setSearchPage] = React.useState<"root" | "clients">("clients");
  const [search, setSearch] = React.useState("");
  
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (
        (navigator?.platform?.toLowerCase().includes("mac")
          ? e.metaKey
          : e.ctrlKey) &&
        e.key === "k"
      ) {
        e.preventDefault();
        e.stopPropagation();
        
        setSearchPage("root");
        setSearch("");
        setSearchOpen((currentValue) => {
          return !currentValue;
        });
      }
    }
    
    document.addEventListener("keydown", handleKeyDown);
    
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  
  
  return {
    searchOpen, setSearchOpen,
    searchPage, setSearchPage,
    search, setSearch,
  }
}

export const useSearchContext = () => {
  const context = React.useContext(getSearchContext());
  if (context === null) {
    throw new Error("useSearchContext must be used within a SearchContextProvider");
  }
  return context;
};
