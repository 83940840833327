import React, { FC } from 'react';
import {Loader, LoaderProps} from 'rsuite';
import {useScreenLoading} from "../context/ScreenLoadingContext";

interface IOwnProps {
  backgroundColor?: string;
  fullScreen?: boolean;
}

type Props = IOwnProps & LoaderProps;
const Loading: FC<Props > = ({backgroundColor = "white", fullScreen = true, ...rest}) => {
  
  const {addElement, removeElement} = useScreenLoading();
  
  React.useEffect(() => {
    if(!fullScreen){
      return;
    }
    const loadingId = `${Math.random() * 100000000}`;
    addElement({id: loadingId, timeout: 10, fadeInDuration: 10})
    
    return () => {
      removeElement(loadingId);
    }
  }, [])
  
  return (
    <div style={{
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor,
      zIndex: 100,
    }}>
        <Loader {...{size: "lg", ...rest}} />
    </div>
  );
};


export default Loading;
